import HeaderElement from "@common/HeaderElement";
import { useStoreState } from "easy-peasy";
import styled from "styled-components";

const HeaderRow = () => {
  const { isMobile, isMiniTablet } = useStoreState((state) => state.media);
  const miniTabletOrMobile = isMiniTablet || isMobile;
  return (
    <THead>
      <tr>
        <th></th>
        <HeaderElement text="Name" name="name" />
        {!miniTabletOrMobile && (
          <>
            <HeaderElement $width="310px" text="Community" name="community" />
            <HeaderElement text="Date" name="created_at" />
          </>
        )}
        <th></th>
      </tr>
    </THead>
  );
};

const THead = styled.thead`
  th:first-child {
    width: 30px;
  }
  th {
    padding: 24px;
    text-align: left;
  }
`;
export default HeaderRow;
