import TabWrapper from "@common/TabWrapper";
import { Box, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import TableBadges from "./TableBadges";
const ProfileBadges = ({ isPublicProfile }) => {
  const navigate = useNavigate();
  const { user } = useStoreState((state) => state.user);
  const { username } = useStoreState((state) => state.authentication.publicUser);

  const title = isPublicProfile ? `${username}’s badges` : "My Badges";

  const onCommunityClick = (e) => navigate(`/communities/${e.target.dataset.id}`);

  return (
    <TabWrapper>
      <Box $sm-spacing="p-4">
        <StyledText $typography="heading/large" $color="01Primary700" $spacing="pt-2" $bold>
          {title}
        </StyledText>
        <TableBadges data={user.user_badges} onCommunityClick={onCommunityClick} />
      </Box>
    </TabWrapper>
  );
};

const StyledText = styled(Text)`
  word-wrap: break-word;
`;

export default ProfileBadges;
