import { BadgeCardProfile, breakpoints } from "@thrivecoin/ui-components";
import { formatDate } from "@utils/formatting";
import { useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LoaderTable from "../../CommunityPage/Challenges/Contributions/LoaderTable";
import HeaderRow from "./HeaderRow";

const TableBadges = ({ data, loading }) => {
  const navigate = useNavigate();

  const { isMobile } = useStoreState((state) => state.media);
  const doCommunity = (event) => {
    const id = event.target.dataset.id;
    navigate(`/communities/${id}`);
  };

  return (
    <>
      <Table>
        {!isMobile && <HeaderRow />}
        {!loading && (
          <tbody>
            {data?.map((item) => (
              <>
                <BadgeCardProfile
                  key={item.id}
                  doCommunity={doCommunity}
                  {...item}
                  created_at={formatDate(item.created_at)}
                />
                <Spacing />
              </>
            ))}
          </tbody>
        )}
      </Table>
      <LoaderTable loading={loading} />
    </>
  );
};

export const Table = styled.table`
  width: 100%;
  margin-top: 32px;
  position: relative;
  overflow-x: scroll;
  border-collapse: separate;
  border-spacing: 0;
  th {
    padding: 0 24px 12px 24px;
  }
`;

const Spacing = styled.tr`
  display: block;
  margin-bottom: 24px;
  @media ${breakpoints.mobile} {
    margin-bottom: 16px;
  }
`;

export default TableBadges;
